body {
    margin: 0;
  }
 
  .header {
    background-color: rgb(246, 244, 244);
    border-bottom: 1px solid black;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
 
  .logo {
    width: 150px;
    height: auto;
  }
 
  .header-logout-btn {
    padding: 10px;
    width: 150px;
    background: rgb(0, 0, 112);
    color: white;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
 
  .header-logout-btn:hover {
    background: rgb(13, 13, 181);
  }
 
  .form-parent {
    height: calc(100vh - 91px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
  .main-form {
    border-radius: 5px;
    border: 0.5px solid gray;
    background-color: rgb(246, 244, 244);
    width: 500px;
    height: 600px;
  }
 
  .form-row{
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
 
  .booking-form {
    padding: 20px;
  }
 
  .main-form h1 {
    text-align: center;
    margin-bottom: 50px;
  }
 
  .input-label {
    width: 95%;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 5px;
    color: rgb(90, 90, 90);
  }
 
  .input {
    margin-bottom: 20px;
    height: 15px;
    padding: 10px;
    width: 96%;
  }
 
  .select-input {
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
 
  .btn-parent {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
 
  .form-submit-btn {
    padding: 10px;
    width: 150px;
    background: rgb(0, 0, 112);
    color: white;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
 
  .form-submit-btn-disabled{
    padding: 10px;
    width: 150px;
    background: rgb(134, 134, 134);
    color: white;
    font-weight: bold;
    border-radius: 5px;
  }
 
  .form-submit-btn:hover {
    background: rgb(13, 13, 181);
  }
 
  .preview-header {
    border-bottom: 1px solid black;
    padding: 15px;
    font-weight: bold;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    margin: 0;
  }
 
  .preview-header-property-name {
    width: 100%;
    font-size: 30px;
    overflow-x: hidden;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
 
  .preview-field-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 10px 0;
  }
 
  .preview-field {
    font-size: larger;
    width: 100%;
  }
 
  .btn-preview {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
 
  .thank-text {
    text-align: center;
    font-size: larger;
  }
 
  .thank-email {
    font-weight: bold;
    text-align: center;
    font-size: 30px;
  }
 
  .error {
    font-size: 20px;
    color: red;
    text-align: center;
  }
 
  .loading{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }