.info {
  display: block;
}
.statusDot {
  display: block;
}

.MuiGrid-justify-content-xs-center {
  justify-content: flex-start !important;
}

@media only screen and (max-width: 440px) {
  .info {
    display: none !important;
  }
  .statusDot {
    display: none !important;
  }
}
